<template>
	<div>

		<nav class="navbar-nav">
			<nav aria-label="breadcrumb">
				<ol class="breadcrumb">
					<li class="breadcrumb-item">
						<router-link :to="{name: 'projects-index'}">Projects</router-link>
					</li>

					<li class="breadcrumb-item" v-if="project">
						<router-link :to="{
								name: 'projects-editor-uuid-index',
								params: {
									uuid: project.Uuid
								}
							}" v-text="`${project.Code} - ${project.Name}`"></router-link>
					</li>

					<li class="breadcrumb-item active">
						<router-link :to="{
						name: 'projects-editor-uuid-cuuid-localizations-index',
						params: {
							uuid: this.$route.params.uuid,
							cuuid: this.$route.params.cuuid
						}
					}" v-if="configuration">{{ configuration.Name }} {{ `v. ${configuration.Version}` }}
						</router-link>
					</li>

					<li class="breadcrumb-item active">
						Localization
					</li>

				</ol>
			</nav>

		</nav>

		<div v-if="loading">Loading...</div>
		<template v-else>

			<div class="d-flex justify-content-end mb-4">
				<router-link class="btn btn-primary mt-4" :to="{
										name: 'projects-editor-uuid-cuuid-localizations-new-project',
										params: {
											uuid: $route.params.uuid,
											cuuid: $route.params.cuuid
											}
				}">NEW LOCALIZATION PROJECT
				</router-link>
			</div>

			<div class="p-5 bg-light d-flex align-items-center justify-content-center"
					 v-if="localizationProjects.length === 0">
				<div>
					<h3>NO LOCALIZATION PROJECTS FOUND</h3>
					<hr>
					<div class="my-3">
						<div class="my-2" v-if="project && configuration">Create your first <strong>{{ project.Name }} /
							{{ configuration.Name }} <sup class="text-small">(v. {{ configuration.Version }})</sup></strong> based
							localization project
						</div>
						<div class="my-2">You need a localization project for each game using this Math</div>
					</div>
					<router-link class="btn btn-primary mt-4" :to="{
										name: 'projects-editor-uuid-cuuid-localizations-new-project',
										params: {
											uuid: $route.params.uuid,
											cuuid: $route.params.cuuid
											}
				}">NEW LOCALIZATION PROJECT
					</router-link>
				</div>
			</div>

			<div v-else>
				<template v-for="lproject in localizationProjects">
					<div class="d-flex align-items-center">
						<div class="flex-grow-1">{{ lproject.Name }}</div>
						<div class="d-flex justify-content-between">
			  			<router-link :to="{
										name: 'projects-editor-uuid-cuuid-localizations-project_id-pay_table-index',
										params: {
											uuid: $route.params.uuid,
											cuuid: $route.params.cuuid,
											project_id: lproject.Id
											}
							}" class="btn btn-outline-dark mr-2 my-1 w-100 d-flex align-items-center justify-content-center">Edit pay table</router-link>
							<router-link :to="{
										name: 'projects-editor-uuid-cuuid-localizations-project_id-guide-index',
										params: {
											uuid: $route.params.uuid,
											cuuid: $route.params.cuuid,
											project_id: lproject.Id
											}
							}" class="btn btn-outline-info mr-2 my-1 w-100 d-flex align-items-center justify-content-center">Edit game guide</router-link>
							<router-link :to="{
										name: 'projects-editor-uuid-cuuid-localizations-project_id-content',
										params: {
											uuid: $route.params.uuid,
											cuuid: $route.params.cuuid,
											project_id: lproject.Id
											}
							}" class="btn btn-outline-primary mr-2 my-1 w-100 d-flex align-items-center justify-content-center">Edit game content</router-link>
							<router-link :to="{
										name: 'projects-editor-uuid-cuuid-localizations-project_id-download',
										params: {
											uuid: $route.params.uuid,
											cuuid: $route.params.cuuid,
											project_id: lproject.Id
											}
							}" class="btn btn-outline-success mr-2 my-1 w-100 d-flex align-items-center justify-content-center">Download</router-link>
						</div>
					</div>
					<hr>
				</template>
			</div>

			<!--
			<router-link :to="{
					name: 'projects-editor-uuid-cuuid-localizations-upload',
				params: {
					uuid: $route.params.uuid,
					cuuid: $route.params.cuuid
					}
				}" class="btn btn-outline-primary">Upload a different file
			</router-link>
			<p class="bg-light p-3">Every unused translation stored in system but not present in JSON file will be deleted</p>

			<div v-if="tokens.length === 0">
				<h3>No translation loaded for this game</h3>
				<router-link :to="{
					name: 'projects-editor-uuid-cuuid-localizations-upload',
				params: {
					uuid: $route.params.uuid,
					cuuid: $route.params.cuuid
					}
				}" class="btn btn-primary">Upload a locale file
				</router-link>
			</div>
			<div v-else>
				<localization-editor @changed="setChanged" v-model="tokens"></localization-editor>
				<button class="btn btn-primary" :disabled="saving" @click="save">SAVE</button>
				<br>
				<br>
				<br>
				<br>
			</div>
		-->
		</template>
	</div>
</template>

<script>
import api from '@/api'
import LocalizationEditor from "@/components/localizations/LocalizationEditor";
import messageBox from '@/components/modals/alert'

export default {
	name: "index",
	components: {LocalizationEditor},
	data: () => ({
		project: null,
		configuration: null,
		localizationProjects: [],
		loading: false,
	}),
	async mounted() {
		this.loading = true
		this.project = await api.Projects.getProject(this.$route.params.uuid)
		this.configuration = await api.Projects.getProjectConfig(this.$route.params.cuuid)
		await this.loadLocalizationProjects()
		this.loading = false
	},
	methods: {
		async loadLocalizationProjects() {
			this.localizationProjects = await api.Localizations.LocalizationProjects.getList({filters: JSON.stringify({ProjectConfigfk: this.configuration.Id})})
		}
	}
}
</script>

<style scoped>

</style>
