var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('nav',{staticClass:"navbar-nav"},[_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{name: 'projects-index'}}},[_vm._v("Projects")])],1),(_vm.project)?_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{
							name: 'projects-editor-uuid-index',
							params: {
								uuid: _vm.project.Uuid
							}
						}},domProps:{"textContent":_vm._s(`${_vm.project.Code} - ${_vm.project.Name}`)}})],1):_vm._e(),_c('li',{staticClass:"breadcrumb-item active"},[(_vm.configuration)?_c('router-link',{attrs:{"to":{
					name: 'projects-editor-uuid-cuuid-localizations-index',
					params: {
						uuid: this.$route.params.uuid,
						cuuid: this.$route.params.cuuid
					}
				}}},[_vm._v(_vm._s(_vm.configuration.Name)+" "+_vm._s(`v. ${_vm.configuration.Version}`)+" ")]):_vm._e()],1),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v(" Localization ")])])])]),(_vm.loading)?_c('div',[_vm._v("Loading...")]):[_c('div',{staticClass:"d-flex justify-content-end mb-4"},[_c('router-link',{staticClass:"btn btn-primary mt-4",attrs:{"to":{
									name: 'projects-editor-uuid-cuuid-localizations-new-project',
									params: {
										uuid: _vm.$route.params.uuid,
										cuuid: _vm.$route.params.cuuid
										}
			}}},[_vm._v("NEW LOCALIZATION PROJECT ")])],1),(_vm.localizationProjects.length === 0)?_c('div',{staticClass:"p-5 bg-light d-flex align-items-center justify-content-center"},[_c('div',[_c('h3',[_vm._v("NO LOCALIZATION PROJECTS FOUND")]),_c('hr'),_c('div',{staticClass:"my-3"},[(_vm.project && _vm.configuration)?_c('div',{staticClass:"my-2"},[_vm._v("Create your first "),_c('strong',[_vm._v(_vm._s(_vm.project.Name)+" / "+_vm._s(_vm.configuration.Name)+" "),_c('sup',{staticClass:"text-small"},[_vm._v("(v. "+_vm._s(_vm.configuration.Version)+")")])]),_vm._v(" based localization project ")]):_vm._e(),_c('div',{staticClass:"my-2"},[_vm._v("You need a localization project for each game using this Math")])]),_c('router-link',{staticClass:"btn btn-primary mt-4",attrs:{"to":{
									name: 'projects-editor-uuid-cuuid-localizations-new-project',
									params: {
										uuid: _vm.$route.params.uuid,
										cuuid: _vm.$route.params.cuuid
										}
			}}},[_vm._v("NEW LOCALIZATION PROJECT ")])],1)]):_c('div',[_vm._l((_vm.localizationProjects),function(lproject){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"flex-grow-1"},[_vm._v(_vm._s(lproject.Name))]),_c('div',{staticClass:"d-flex justify-content-between"},[_c('router-link',{staticClass:"btn btn-outline-dark mr-2 my-1 w-100 d-flex align-items-center justify-content-center",attrs:{"to":{
									name: 'projects-editor-uuid-cuuid-localizations-project_id-pay_table-index',
									params: {
										uuid: _vm.$route.params.uuid,
										cuuid: _vm.$route.params.cuuid,
										project_id: lproject.Id
										}
						}}},[_vm._v("Edit pay table")]),_c('router-link',{staticClass:"btn btn-outline-info mr-2 my-1 w-100 d-flex align-items-center justify-content-center",attrs:{"to":{
									name: 'projects-editor-uuid-cuuid-localizations-project_id-guide-index',
									params: {
										uuid: _vm.$route.params.uuid,
										cuuid: _vm.$route.params.cuuid,
										project_id: lproject.Id
										}
						}}},[_vm._v("Edit game guide")]),_c('router-link',{staticClass:"btn btn-outline-primary mr-2 my-1 w-100 d-flex align-items-center justify-content-center",attrs:{"to":{
									name: 'projects-editor-uuid-cuuid-localizations-project_id-content',
									params: {
										uuid: _vm.$route.params.uuid,
										cuuid: _vm.$route.params.cuuid,
										project_id: lproject.Id
										}
						}}},[_vm._v("Edit game content")]),_c('router-link',{staticClass:"btn btn-outline-success mr-2 my-1 w-100 d-flex align-items-center justify-content-center",attrs:{"to":{
									name: 'projects-editor-uuid-cuuid-localizations-project_id-download',
									params: {
										uuid: _vm.$route.params.uuid,
										cuuid: _vm.$route.params.cuuid,
										project_id: lproject.Id
										}
						}}},[_vm._v("Download")])],1)]),_c('hr')]})],2)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }